// Init menu / navigation.
$(document).ready(function () {
    new Menu();
});

/*
The menu for desktop and mobile shares the same HTML in the DOM.
Most of the functions below are for the desktop version of the menu, which requires a lot of JS based CSS animations.
The mobile menu, in contrast, works mostly with pure CSS and just some minor JS
*/

class Menu {
    constructor() {
        this.isRtl = ($('body').css('direction') === "rtl");
        this.initDesktop();
        this.initMobile();
        this.watchPreferences();
    }

    initMobile() {
        let that = this;
        $('#mobile-menu-toggle').click(function () {
            if (!$(this).hasClass('open')) {
                $(this).addClass('open');
                $('.nav-wrapper').slideDown(function () {
                    $(this).addClass('open');
                    that.addResizeHandler();
                    $('body').addClass('no-scroll');
                });

                $('.back-to-top').removeClass('show');
                $('.container-flag').hide();
            } else {
                that.closeMenu();
                $('.container-flag').show();
            }
        });

        $('#main-nav .back-link').click(function () {
            let link = this;
            if ($('#main-nav').hasClass('level-1')) {
                $('#main-nav').removeClass('level-1');
            } else if ($('#main-nav').hasClass('level-2')) {
                $('#main-nav').removeClass('level-2').addClass('level-1');
            }

            // remove the "open" class from the li AFTER the css animation (margin-left) is completed
            $('#main-nav').on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
                function () {
                    $(link).closest('li.open').removeClass('open');
                    // remove the event listener as well
                    $('#main-nav').off();
                }
            );

            return false;
        });

        // Click on any link with children
        $('#main-nav li.has-children > a').click(function () {
            $('#main-nav').scrollTop(0);

            // When a submenu is shorter as the parent menu, the submenu sometimes still has a vertical scroll bar
            // To avoid this, the items of the parent menu are hidden once the submenu was moved into position
            let parentMenu = $(this).closest('ul');
            $(parentMenu).addClass('opening-sub');
            // remove the "opening-sub" class AFTER the css animation (margin-left) is completed
            $('#main-nav').on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
                function () {
                    $(parentMenu).removeClass('opening-sub');
                    // remove the event listener as well
                    $('#main-nav').off();
                }
            );

        });
    }

    initDesktop() {
        let that = this;

        // click on 1st level
        $('#main-nav > ul > li.has-children > a').click(function () {
            let elem = this;
            if ($(this).parent().hasClass('open')) {
                // clicked item is already opened -> close menu
                that.closeMenu();
            } else {
                // item is currently closed -> open menu
                that.resetMenuClasses();
                $('#main-nav, .nav-wrapper, #mobile-menu-toggle').addClass('open');
                $('#main-nav').addClass('level-1');

                // Add teasers
                that.clearTeaser();
                if ($(this).nextAll('template').length) {
                    that.addTeasers($(this).nextAll('template').html());
                }

                if ($('#main-nav > ul > li.open').length) {
                    // menu is already opened

                    // first hide currently open (sub)menus
                    if (that.isRtl) {
                        $('#main-nav > ul > li.open > ul.open li.open > ul').animate({
                            right: '-33%'
                        }, 300);
                        $('#main-nav > ul > li.open > ul > li').css({
                            marginRight: ''
                        });
                    } else {
                        $('#main-nav > ul > li.open > ul.open li.open > ul').animate({
                            left: '-33%'
                        }, 300);
                        $('#main-nav > ul > li.open > ul > li').css({
                            marginLeft: ''
                        });
                    }

                    that.resetSubMenus();

                    // then prepare showing the new menu
                    $('#main-nav ul, #main-nav li').removeClass('open');
                    $(elem).parent().addClass('open');
                    // adjust the height of the stage to the new menu height
                    that.adjustStageHeight();

                    if (that.isRtl) {
                        $('#main-nav > ul > li.open > ul > li').animate({
                            marginRight: 0
                        }, 300);
                    } else {
                        $('#main-nav > ul > li.open > ul > li').animate({
                            marginLeft: 0
                        }, 300);
                    }

                } else {
                    // menu is currently closed
                    // prepare showing the new menu (it is hidden by setting a negative margin in the css)
                    $(elem).parent().addClass('open');

                    // get the height of the menu (see comments in adjustStageHeight() regarding stage height calculation)
                    let height = $('#main-nav > ul > li.open > ul').height() + 25;
                    $('#main-nav-stage-spacer').height(height);

                    // slide toggle the stage
                    $('#main-nav-stage').slideToggle(function () {
                        if (that.isRtl) {
                            $('#main-nav > ul > li.open > ul > li').animate({
                                marginRight: 0
                            }, 200);
                        } else {
                            $('#main-nav > ul > li.open > ul > li').animate({
                                marginLeft: 0
                            }, 200);
                        }
                    });

                    that.addCloseHandler(); // Closes menu on click outside
                    that.addResizeHandler();
                }

            }
            return false;
        });

        // Click on deeper level with children
        $('#main-nav > ul > li.has-children li.has-children > a').click(function () {
            $(this).closest('ul').find('ul, li').removeClass('open').css({
                left: '',
                right: ''
            });
            $(this).closest('li').addClass('open');
            $(this).closest('ul').addClass('open');

            // Add teasers
            //that.clearTeaser();
            if ($(this).nextAll('template').length) {
                that.addTeasers($(this).nextAll('template').html());
            }

            that.resetSubMenus();

            let subMenuHeight = $(this).nextAll('ul').height();
            $(this).closest('ul').css({
                minHeight: subMenuHeight + 'px'
            });

            // aAdjust the height of the stage first, and then show the respective 2nd/3rd level submenu
            that.adjustStageHeight(that.showSubMenu, this);

            let levelCount = $(this).parentsUntil('nav', 'ul').length;

            that.resetMenuClasses();
            $('#main-nav').addClass('level-' + levelCount);

            return false;
        });

    }

    // Showing the 2nd/3rd level menu is moved to function as it is used as a callback
    showSubMenu(selector) {
        if ($('body').css('direction') === "rtl") {
            $(selector).nextAll('ul').find('> li').animate({
                marginRight: 0
            }, 300);
        } else {
            $(selector).nextAll('ul').find('> li').animate({
                marginLeft: 0
            }, 300);
        }
    }

    /*
    resets the adjustents for 3rd level menus
    - minHeight of the 2nd level
    - marginLeft of the 3rd level
    */
    resetSubMenus() {
        this.resetSubMenuMinHeight();
        $('#main-nav').find('> ul > li > ul > li > ul > li').css({
            marginRight: '',
            marginLeft: ''
        });
    }

    resetSubMenuMinHeight() {
        $('#main-nav').find('> ul > li > ul').css({
            minHeight: ''
        });
    }

    /*
    the height of the stage is determined by the height of #main-nav-teaser-wrapper and #main-nav-stage-spacer
    since the actual navigation markup is not rendered inside the stage, the #main-nav-stage-spacer fakes the navigation height on the stage
    this also allows an animation of the height
    */
    adjustStageHeight(callbackFunction, callbackParameter) {
        let height = 0;
        $('#main-nav > ul li.open > ul, #main-nav-teaser-wrapper:visible').each(function (index, value) {
            let currentHeight = $(this).height();
            if (currentHeight > height) {
                height = currentHeight;
            }
        });
        if (height === 0) {
            return false;
        }

        $('#main-nav-stage-spacer').animate({
            height: height + 25
        }, 300, function () {
            if (typeof callbackFunction !== 'undefined') {
                callbackFunction(callbackParameter)
            }
        });
    }

    /* remove the min-height from the 2nd level menu and replace it by a height attribute */
    /* required for sliding up the navigation, which doesn't work with min-height */
    prepareMenuClose() {
        let minHeight = $('#main-nav > ul > li.open > ul.open').css('minHeight');
        $('#main-nav > ul > li.open > ul.open').height(minHeight);
        this.resetSubMenuMinHeight();
    }

    closeMenu() {
        let that = this;

        // extras for mobile menu
        if ($('.mobile-menu-toggle').is(':visible')) {
            $('#mobile-menu-toggle').removeClass('open');
            $('.nav-wrapper').slideUp(function () {
                $(this).removeClass('open');
                $(this).css({
                    display: ''
                });
            });
        }

        this.prepareMenuClose(); // remove min-height from 2nd level menu
        $('#main-nav-stage').slideUp(); // hide stage with colored border
        $('#main-nav > ul > li.open > ul').slideUp(function () { // slide UL up
            // $(this).find('li').css({ marginLeft: '' });
            $('#main-nav').find('> ul > li > ul > li').css({
                marginLeft: '',
                marginRight: ''
            });
            $('#main-nav > ul > li').removeClass('open');
            $('#main-nav, #main-nav ul, #main-nav li').removeClass('open');
            $(this).css({
                display: ''
            }); // remove 'display: none' from toggle since it was just for the animation
            $('#main-nav ul').css({
                left: '',
                right: '',
                height: ''
            }); // remove any inline styles
            $('#main-nav-stage-spacer').css({
                height: ''
            }); // remove any inline styles
            $('.nav-wrapper, #mobile-menu-toggle').removeClass('open');
            that.resetMenuClasses();
            that.resetSubMenus();
        });
        $('body').removeClass('no-scroll'); // used for mobile menu
        that.removeCloseHandler();
        that.removeResizeHandler();
    }

    resetMenuClasses() {
        $('#main-nav')
            .removeClass('level-1')
            .removeClass('level-2')
            .removeClass('level-3');
    }

    addTeasers(template) {
        $('#main-nav-teaser-wrapper').append(template);
        // Commented out, not a part of the navigation.
        //this.objTeaser.initTeaser('#main-nav-teaser-wrapper'); // truncation and equal height for teasers
    }

    clearTeaser() {
        $('#main-nav-teaser-wrapper').empty();
    }

    /*
    close menu when clicking somewhere outside of it
    a namespace is used for the event handler, so the event handler can be removed again from the document after the menu was closed
    */
    addCloseHandler() {
        let that = this;

        $(document).bind('click.menuHandler', function (event) {
            if (!$(event.target).closest('#main-nav').length) {
                that.closeMenu();
            }
        });

    }
    removeCloseHandler() {
        $(document).unbind('click.menuHandler');
    }

    addResizeHandler() {
        let that = this;

        // use debounce from underscore.js
        let resizeHandler = _.debounce(function () {
            if ($('.mobile-menu-toggle').is(':visible')) {
                // mobile
                $('body').addClass('no-scroll');
            } else {
                // desktop
                $('body').removeClass('no-scroll');
                if ($('#main-nav').is('.level-1, .level-2')) {
                    $('#main-nav-stage').show();
                    that.adjustStageHeight();
                } else {
                    that.closeMenu();
                }
            }
        }, 100);

        $(window).bind('resize.menuHandler', resizeHandler);

    }
    removeResizeHandler() {
        $(window).unbind('resize.menuHandler');
    }

    // watch dynamic teaser containers for changes of the DOM and initialize the teasers on change detection
    watchPreferences() {
        let self = this;
        $('#main-nav .preferences > ul > li').each(function () {
            $(this).bind('DOMSubtreeModified', function (e) {
                if (e.target.innerHTML && e.target.innerHTML.length > 0) { // prevent from firing when DOM is empty, e.g. when cleared first
                    self.adjustStageHeight();
                }
            });
        })
    }
}